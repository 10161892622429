import { ResourceChannel } from "@/services/resources";
import { defineStore } from "pinia";
import { useDataFiltersStore } from "@/store/data_filters";

export const useOrganizationStore = defineStore("organization", {
  state: () => ({
    id: 0,
    dismissedBannerWarning: false,
    dismissedResourceIssueWarning: false,
    routeHasRequiredData: true,
  }),
  actions: {
    DISMISS_RESOURCE_MISSING_BANNER() {
      this.dismissedBannerWarning = true;
    },
    DISMISS_RESOURCE_ISSUE_BANNER() {
      this.dismissedResourceIssueWarning = true;
    },
    setRouteHasRequiredData(value: boolean): void {
      this.routeHasRequiredData = value;
    },
  },
});

export function useDataFilterInitialization({
  channels,
}: {
  channels: readonly ResourceChannel[];
}): void {
  const dataFiltersStore = useDataFiltersStore();
  dataFiltersStore.initializeDataFilters({
    availableChannels: channels,
  });
}

// Google Drive Picker Access Token
export const useGDPTokenStore = defineStore("gdpToken", {
  state: () => ({
    accessToken: "",
    expiresIn: 0,
  }),

  actions: {
    setTokenInfo(accessToken: string, expiresIn: number): void {
      this.accessToken = accessToken;
      this.expiresIn = expiresIn;
    },
  },
});
